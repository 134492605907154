@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.linkedin {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 16px;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
}

.mobileNumber {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 16px;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #7A7A7A;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
  }
}

.termsLink {
  composes: marketplaceModalHelperLink from global;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #F39663;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
  }
}

.checkboxImage {
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }
}
