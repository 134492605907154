@import '../../styles/customMediaQueries.css';


.root {
  padding: 40px 0;
  background-color: var(--lightGray);
  user-select: none;

  @media (--viewportMedium) {
    padding: 80px 0;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  flex-direction: column;

  max-width: 1352px;
  padding: 0 24px;
  margin: 0 auto;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
  }
  
  @media (--viewport1440) {
    padding: 0 36px;
  }
}

.contentItem {
  
  @media (--viewportMedium) {
    width: calc(50% - 20px);
  }
}

.title {
  margin: 0 0 32px;
  font-size: 24px;
  line-height: 32px;
  font-weight: var(--fontWeightMedium);
  color: var(--customBlack);
  text-align: center;
  
  @media (--viewportMedium) {
    font-size: 36px;
    line-height: 46px;
    text-align: left;
    color: var(--darkBlue);
  }

  & br {

    @media (--viewportMedium) {
      display: none;
    }
  }
}

.stepItemRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.stepItemFigure {
  background-color: #EFEFEF;
  border-radius: 24px 24px 0 24px;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #BABABA;
  width: 48px;
  min-width: 48px;
  height: 48px;
  text-align: center;
  padding-top: 8px;
  margin-right: 25px;

  @media (--viewportMedium) {
    margin-right: 35px;
  }
}

.stepItemActiveFigure {
  background-color: var(--marketplaceColor);
  border-radius: 24px 24px 0 24px;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: white;
  min-width: 48px;
  width: 48px;
  height: 48px;
  text-align: center;
  padding-top: 8px;
  margin-right: 25px;

  @media (--viewportMedium) {
    margin-right: 35px;
  }
}

.stepItemText {
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.2px;
  padding-top: 8px;
  color: #EFEFEF;

  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.stepItemActiveText {
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.2px;
  padding-top: 8px;
  color: var(--darkBlue);

  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.stepItemPipe {
  border-left: 1px solid #e2e3e3;
  margin-left: 23px;
  width: 100px;
  height: 32px;
  
  @media (--viewportMedium) {
    border-left: 1px solid transparent;
  }
}

.stepText {
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 17px;
  font-weight: 300;
  color: #9F9F9F;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}


.button {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  min-height: 44px;
  line-height: 44px;
  margin: 40px auto 0;
  padding: 0;
  border-radius: 10px;

  background-color: var(--matterColorLight);
  border: 1px solid var(--marketplaceColor);
  color: var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    min-height: 50px;
    line-height: 50px;
    max-width: 200px;
    margin: 60px auto 0;
  }
}

.videoWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  @media (--viewportMedium) {
    margin-top: 0;
  }

  & iframe {
    height: auto;
    min-height: 182px;
    border-radius: 10px;
    max-width: 100%;

    @media (--viewportLarge) {
      min-height: 296px;
    }
  }
}
