@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
}

.longInfo {
  flex-wrap: wrap;
}

.requestClause {
  width: 100%;
  height: 435px;
  background-color: #1C2431;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 24px;
  padding-top: 24px;
  background-image: url(./background-mobile.png);
  background-position: top 47px right;

  @media(--viewportMedium){
    border-radius: 50px;
    padding-top: 80px;
    height: 490px;
    background-image: url(background-desktop-left-bottom.png), url(background-desktop-right-top.png);
    background-position: left bottom, right top;
    background-repeat: no-repeat, no-repeat;
  }

  @media screen and (min-width: 781px) {
    background-color: white;
    background-image: url(./background-desktop.png);
    height: 490px;
    width: 781px;
    margin-left: auto;
    margin-right: auto;
  }
}

.requestMain {
  width: 100%;
  height: 100%;
}

.requestHeader {
  width: 100%;
  padding: 0;
  text-align: center;

  @media(--viewportMedium){
    padding: 0;
  }
}

.headerTitle {
  width: 100%;
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;

  @media(--viewportMedium) {
    font-size: 24px;
    line-height: 140%;
    max-width: 608px;
    margin-left: auto;
    margin-right: auto;
  }
}

.headerSubtitle {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

.fieldsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 25px;

  @media(--viewportMedium) {
    padding: 0 80px;
  }
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.searchResultSummary {
  composes: h4 from global;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.noSearchResults {
  composes: h4 from global;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
  max-height: 40px;
}

.loadingResults {
  composes: h4 from global;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: white;

  @media (--viewportLarge) {
    padding: 9px 36px 0 36px;
  }
}

.resultsFound {
  white-space: nowrap;
}

.searchFiltersPanelClosed {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;

  display: inline-block;
  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);

  display: inline-block;
  padding: 9px 16px 9px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.enquiryForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: static;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  margin-top: 5px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 5px;
  }
}
