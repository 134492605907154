@import '../../styles/customMediaQueries.css';

.root {

}

.titleRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.title {
  composes: h1 from global;

  margin-top: 0;
  margin-bottom: 0;
  margin-right: auto;
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 29px;
  letter-spacing: 0.2px;
  color: var(--customBlack);

  @media (--viewportMedium) {
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
  }
}

.arrowBullets {
  width: 150px;
  text-align: right;
}

.arrowBulletsMobile {
  width: 150px;
  text-align: left;
  margin-top: 20px;
}

.arrowImage {
  width: 50px;
  height: 45px;

  &:hover {
    cursor: pointer;
  }
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
  }
}

.location {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  composes: h2 from global;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 0.2px;

  @media (--viewportMedium) {
    margin-top: 21px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }
}

.linkSubText {
  composes: h2 from global;
  color: #757575;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  margin-top: 15px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  @media (--viewportMedium) {
    margin-top: 21px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
  }
}

.locationName {
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: var(--darkBlue);

  @media (--viewportMedium) {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }
}
