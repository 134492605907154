@import '../../styles/customMediaQueries.css';

.root {
  padding: 0 0 80px 0;
  
  @media (--viewportMedium) {
    padding: 0 0 140px 0;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  width: calc(100% - 48px);
  max-width: 1280px;
  padding: 20px 20px;
  margin: 0 auto;
  border-radius: 20px;
  
  background: linear-gradient(96.66deg, #F3A274 0%, #D4EAE7 49.5%, #FAF9E4 100%);

  @media (--viewportMedium) {
    width: 100%;
    padding: 40px 80px;
  }
  
  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 80px;
  }
}

.contentItem {

  @media (--viewportLarge) {
    &:first-child {
      flex: 0 1 686px;
    }
  
    &:last-child {
      flex: 0 0 332px;
    }
  }
}

.title {
  margin: 0 0 32px;
  font-size: 24px;
  line-height: 32px;
  font-weight: var(--fontWeightMedium);
  color: var(--darkBlue);
  text-align: center;

  @media (--viewportMedium) {
    font-size: 36px;
    line-height: 46px;
    text-align: left;
  }
}

.subTitle {
  margin: 0 0 32px;
  color: var(--gray);
  font-weight: var(--fontWeightRegular);
}

.img {
  display: block;
  width: 100%;


  & img {
    display: block;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }
}


.form {
  
  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  & input,
  & button {
    height: 44px;
    min-height: unset;
    border-radius: 10px;
    
    @media (--viewportMedium) {
      height: 52px;
    }
  }

  & input {
    background-color: var(--matterColorLight);
    border-color: var(--matterColorLight);
  }
}

.input {
  flex: 0 1 100%;
}

.button {
  flex: 0 0 200px;
  margin: 10px 0 0 0;
  
  @media (--viewportMedium) {
    margin: 0 0 0 10px;
  }
}
