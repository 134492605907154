@import '../../styles/customMediaQueries.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.2px;
  text-align: left;
  margin-top: 30px;

  @media (--viewportMedium) {
    line-height: 27px;
    letter-spacing: 0px;
  }
}

.subheading {
  composes: marketplaceModalTitleStyles from global;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-align: left;
  margin-top: 30px;
}

.field {
  margin: 25px 0 24px 0;
  width: calc( 100% - 48px );

  @media (--viewportMedium) {
    margin: 22px 0 24px 0;
  }
}

.inputRoot {
  min-height: 200px;
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}
