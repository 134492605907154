@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.fieldRow {
  composes: formMargins;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.title {
  composes: formMargins;
  width: 100%;
  margin-bottom: 0;

  @media (--viewportMedium) {
    width: calc( 50% - 12px );
    margin-right: 12px;
  }
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.category {
  composes: formMargins;
  width: 100%;
  margin-bottom: 0;
  margin-top: 24px;

  @media (--viewportMedium) {
    width: 50%;
    margin-top: 0;
  }
}

.startDate {
  composes: formMargins;
  width: 100%;
  margin-bottom: 0;

  @media (--viewportMedium) {
    width: calc( 50% - 12px );
    margin-right: 12px;
  }
}

.endDate {
  composes: formMargins;
  width: 100%;
  margin-bottom: 0;
  margin-top: 24px;

  @media (--viewportMedium) {
    width: 50%;
    margin-top: 0;
  }
}

.cantFindJobLink {
  width: 100%;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  color: #061F29;
  margin-bottom: 24px;

  &:hover {
    cursor: pointer;
  }

  @media (--viewportMedium) {
    max-width: 220px;
    margin-bottom: 32px;
  }
}

.infoIcon {
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-left: 8px;

  @media (--viewportMedium) {
    margin-top: 5px;
  }
}

.tooltipText {
  border-radius: 16px;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #FFFFFF;
  background-color: #061F29;
  padding: 20px;
}

.jobNotFoundTooltip {
  text-align: center;
}

.organisation {
  composes: formMargins;
}

.publicity {
  composes: formMargins;
  max-width: 300px;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #7A7A7A;
}

.currentJob {
  composes: formMargins;
}

.jobDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin-bottom: 12px;

  border: none;
  background-color: var(--matterColor);

  @media (--viewportLarge) {
    margin-bottom: 16px;
  }
}

.addJobButton {
  flex-shrink: 0;
  margin-bottom: 12px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 168px;
    margin-bottom: 0;
  }
}

.removeJobButton {
  flex-shrink: 0;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 2px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
  background-color: white;

  &:hover, &:focus {
    cursor: pointer;
    background-color: white;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: 168px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}
