@import '../../styles/customMediaQueries.css';

.root {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 100px 24px 80px 24px!important;
  background-color: #061F29;

  @media (--viewportMedium) {
    padding: 160px 24px 0 24px!important;
  }

  @media (--viewportLarge) {
    padding: 0 0 0 0;
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
    padding-bottom: 80px;
  }

  @media (--viewportLarge) {
    padding-top: 93px;
    padding-bottom: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1280px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-right: 12px;
  text-align: center;
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex-basis: 220px;
  display: none;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: block;
    order: 1;
    margin-right: 12px;
    flex-basis: 170px;
  }

  @media (--viewportLarge) {
    flex-basis: 260px;
    margin-right: 57px;
  }
  @media (--viewportLargeWithPaddings) {
    flex-basis: 290px;
    margin-right: 12px;
  }
}

.organizationMobile {
  width: 100%;
  display: block;
  margin-right: 24px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.logoMobileLine {
  width: 100%;
  display: block;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  max-width: 128px;
  height: 58px;
}

.logoMobile {
  width: 110px;
  height: auto;
}

.organizationInfo {
}

.organizationDescription,
.organizationCopyright {
  composes: marketplaceTinyFontStyles from global;
}

.organizationCopyright {
  margin-top: 12px;
  font-size: 14px;

  @media (--viewportMedium) {
    margin-top: 80px;
  }
}

.copyrightLink {
  color: white;
  transition: var(--transitionStyleButton);
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  flex-basis: 156px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

.infoLinksMobile {
  flex-basis: 60%;
  margin-right: 24px;
  
  @media (--viewportMedium) {
    display: none;
    margin-bottom: 24px;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 20px;
  margin-bottom: 12px;
  
  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link {
  /* Font */
  composes: h5 from global;
  color: white;
  transition: var(--transitionStyleButton);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 125px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

.searchesMobile {
  flex-basis: 100px;
  margin-right: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 205px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

.searchesExtraMobile {
  flex-basis: 100%;
  margin-right: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 250px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.extraLinksMobile {
  display: block;
  width: 100%;
  margin-bottom: 0;
}

.socialLinks {
  margin-top: 8px;
  text-align: right;
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  min-width: 240px;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.legalLink,
.privacy,
.terms {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-size: 14px;
  color: white;
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;
  margin-right: 20px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-size: 14px;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}
