@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
  height: auto;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.formTitle {
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 135%;
  letter-spacing: 0.2px;
  color: #061F29;
  margin-top: 80px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    font-size: 36px;
    marin-bottom: 50px;
  }
}

.roleBlock {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 16px;
  height: 80px;
  border: 1px solid #E4E4E4;
  border-radius: 24px;
  width: 100%;
  padding: 30px 0 15px 20px;

  @media (--viewportMedium) {
    margin-top: 20px;
    height: 107px;
    border-radius: 42px;
    padding: 40px 0 25px 40px;
  }

  &:hover {
    cursor: pointer;
    border: 2px solid #F39663;
    background-image: url(./arrow-right.png);
    background-size: auto;
    background-position: right 20px center;
  }
}

.roleSectionTitle {
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 114%;
  text-align: left;
  letter-spacing: 0.2px;
  color: #061F29;

  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.roleSectionText {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #9C9C9C;
  width: 250px;

  @media (--viewportMedium) {
    font-size: 16px;
    width: 290px;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
}

.email {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 16px;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
}

.firstNameRoot {
  width: 100%;
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 16px;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
}

.linkedin {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 16px;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
}

.mobileNumber {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 16px;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 40px;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  text-align: left;
}

.checkboxImage {
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }
}

.termsText {
  composes: marketplaceModalHelperText from global;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #7A7A7A;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
  }
}

.termsLink {
  composes: marketplaceModalHelperLink from global;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #F39663;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
  }
}

.signinBlock {
  margin-top: 15px;
  width: 100%;
  text-align: left;
}

.signinLink {
  composes: marketplaceModalHelperLink from global;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #F39663;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
  }
}
