@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 1;
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    min-height: calc(100vh - var(--topbarHeightDesktop));
    text-align: center;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 18px 0 0 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 100%;
    padding: 23px 0 0 0;
  }

  @media screen and (min-width: 1336px) {
    flex-basis: 1336px;
    max-width: 1336px;
    margin-left: auto;
    margin-right: auto;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.cardViewToggleLine {
  display: none;

  @media (--viewportMedium) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 50px;
    padding-bottom: 20px;
  }
}

.cardViewToggleIcon {
  margin-left: 25px;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.searchFiltersPrimary {
  display: flex;

  @media (--viewportMedium) {
    display: flex;
    padding: 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.searchRequestClause {
  display: flex;

  @media (--viewportMedium) {
    display: flex;
    padding: 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

/* Search */
.searchLink {
  flex-grow: 1;

  @media (--viewportLarge) {
    padding-left: 0;
    margin-right: 24px;
  }
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

.closeMobile {
  color: var(--marketplaceColor);
  border: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    display: none;
  }
}

.closeDesktop {
  display: none;

  @media (--viewportMedium) {
    display: block;
    color: var(--marketplaceColor);
    border: none;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}


.closeText {
  composes: marketplaceModalCloseText from global;
}

.filtersLine1 {
  display: flex;
  flex-direction: row;
  width: 100%;

  @media(--viewportMedium) {
    width: auto;
    flex-grow: 1;
  }
}

.filtersLine2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  padding-right: 32px;
  justify-content: space-between;

  @media(--viewportMedium) {
    width: auto;
    margin-top: 0;
    padding-right: 0;
  }
}

.filtersLine3 {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  padding-right: 32px;
  justify-content: space-between;

  @media(--viewportMedium) {
    width: auto;
    margin-top: 0;
    padding-right: 0;
  }
}

.topbarSearchWithLeftPadding {
  padding-left: 0;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  border-radius: 16px;

  @media (--viewportLarge) {
    padding-left: 0;
    border-radius: 16px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  visibility: hidden;

  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: none;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  composes: button marketplaceTinyFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}
