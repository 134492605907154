@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding-top: 1px;
}

.title,
.heading,
.subheading,
.timezonePicker,
.weekDay,
.hasEntries,
.submitButton {
  padding-left: 30px;
  padding-right: 30px;

  @media (--viewportSmall) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.heading {
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  color: #212121;
}

.subheading {
  margin: 0;
  padding-top: 19px;
  padding-bottom: 5px;
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  color: #212121;
}

.error {
  color: var(--failColor);
}

.timezonePicker {
  margin-bottom: 24px;
}

.week {
  display: flex;
  flex-direction: column;

  padding-top: 16px;
  margin-bottom: 23px;

  @media (--viewportLarge) {
    margin-bottom: 95px;
  }
}

.weekDay {
  composes: marketplaceTabNavHorizontalFontStyles from global;

  /* Layout */
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  padding-bottom: 19px;
}

.hasEntries {
  background-color: var(--matterColorLight);
}

.dayOfWeek {
  width: 160px;
  padding-top: 8px;
  @media (--viewportSmall) {
    width: 200px;
  }
}

.timePicker {
  width: 100%;
}

.fieldWrapper {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;
  padding-bottom: 6px;

  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 41px;
    left: 0;
    width: 100%;
    z-index: 0;
  }
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: flex-start;
  align-items: center;

  /* parent aka fieldWrapper is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;

  z-index: 1;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;

  stroke: none;
  fill: var(--matterColorAnti);

  &:hover {
    fill: var(--matterColor);
  }

  /* align icon with the select fields */
  padding: 2px 0 3px;
}

.dashBetweenTimes {
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 0;
}

.buttonSetHours {
  padding-top: 8px;
}

.buttonAddNew {
  composes: h5 from global;
  color: var(--matterColor);
}
.submitButton {
  margin-top: auto;
  flex-shrink: 0;
  /* Mobile phones introduce bottom-bar, for which we need to give 96px vertical space */
  padding-bottom: 96px;
}

@media (--viewportMedium) {
  .root {
    padding-top: 2px;
    margin-top: -16px;
  }
  .title {
    margin-bottom: 32px;
  }

  .buttonAddNew {
    margin-bottom: 6px;
  }

  .submitButton {
    padding-bottom: unset;
  }
}

@media (--viewportLarge) {
  .calendarWrapper {
    flex-grow: 0;
    margin-bottom: 24px;
  }
}

.rootSelect {
  @media (--viewportMedium) {
    width: 100px;
  }
}

.fieldSelect {
  composes: marketplaceTabNavHorizontalFontStyles from global;

  background: none;
  padding-right: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 42px;

  &:select {
    margin-right: 0;
  }
}
