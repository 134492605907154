
    font-weight: var(--fontWeightSemiBold);
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.5px;
    
    margin-top: 18px;
    margin-bottom: 18px;

    @media (--viewportMedium) {
      font-size: 40px;
      font-weight: var(--fontWeightSemiBold);
      line-height: 48px;
      letter-spacing: -1px;
      
      margin-top: 24px;
      margin-bottom: 24px;
    }
  