@import '../../styles/customMediaQueries.css';

.root {
  padding: 0 0 40px 0;
  
  @media (--viewportMedium) {
    padding: 0 0 80px 0;
  }
}

.content {
  max-width: 1180px;
  padding: 0 24px;
  margin: 0 auto;
  text-align: center;
  
  @media (--viewport1440) {
    padding: 0 36px;
  }

}

.title {
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: var(--fontWeightMedium);
  font-size: 36px;
  line-height: 46px;
  
  text-align: center;
  color: var(--darkBlue);

  @media (--viewportMedium) {
    font-size: 48px;
    line-height: 62px;
    margin: 0 0 32px 0;
  }
}

.subTitle {
  margin: 0 0 32px;
  color: var(--gray);
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 1.5;
}

.img {
  display: block;
  width: calc(100% - 48px);
  margin: 0 auto;
  
  @media (--viewportMedium) {
    width: 100%;
  }


  & img {
    display: block;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }
}
