@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 8px -5px 41px rgba(0, 0, 0, 0.06);
  border-radius: 24px;
  margin-bottom: auto;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
  padding: 16px;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 67%; /* 3:2 Aspect Ratio */
  background: white; /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc( 100% - 32px );
  border-radius: 16px;
  margin: 16px;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0 16px 16px 16px;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  flex-grow: 1;
}

.priceValue {
  /* Font */
  composes: h2 from global;
  color: #061F29;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-align: right;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.favoriteHeart {
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 10;
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.ratingInfo {
  display: flex;
  flex-direction: row;
}

.averageRating {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);
  display: flex;
  flex-direction: row;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.ratingStarImage {
  margin-right: 6px;
  margin-top: 4px;
  width: 16px;
  height: 16px;
}

.ratingValue {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: right;

  color: #061F29;
}

.numberOfReviews {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9E9E9E;
  margin-left: 5px;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.mainInfo {
  margin: 0 16px 16px 16px;
}

.authorName {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #061F29;
  text-align: left;
}

.currentPosition {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #F39663;
  text-align: left;
}

.description {
  /* Font */
  composes: h3 from global;
  margin-top: 8px;
  margin-bottom: 0;
  text-align: left;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #061F29;

  @media (--viewportMedium) {
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.sectors {
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sectorsLabel {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #061F29;
  margin-top: 15px;
  margin-right: 10px;
}

.sectorName {
  background: #F1F1F1;
  border-radius: 53px;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #061F29;
  padding: 5px 10px;
  margin-top: 10px;
  margin-right: 10px;
}

.bookFullMeetingButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  width: calc( 100% - 32px );
  min-height: 51px;
  height: 51px;
  max-height: 51px;
  border-radius: 16px;
  padding-top: 17px;
  padding-bottom: 13px;
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  margin: auto 16px 16px 16px;
  flex-grow: 1;

  @media (--viewportMedium) {
    display: block;
    height: 51px;
    font-size: 16px;
    line-height: 94%;
  }
}

.askMeButton,
.bookDiscoveryMeetingButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  width: calc( 100% - 32px );
  min-height: 51px;
  height: 51px;
  max-height: 51px;
  border-radius: 16px;
  border: 1.5px solid #F39663;
  padding-top: 17px;
  padding-bottom: 13px;
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  margin: auto 16px 16px 16px;
  flex-grow: 1;
  color: #F39663;
  background-color: white;

  &:hover {
    color: white;
  }

  @media (--viewportMedium) {
    display: block;
    height: 51px;
    font-size: 16px;
    line-height: 94%;
  }
}

.ownListingMessage {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: blue;
  margin: 16px;
  text-align: center;
}
