@import '../../styles/customMediaQueries.css';

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.formMargins {
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.mobileSubtitle {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  letter-spacing: 0.2px;
  color: #9E9E9E;
  margin-bottom: 10px;

  @media (--viewportMedium) {
    display: none;
  }
}

.languageSubtitle {
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  color: #212121;
  margin-top: 20px;
  margin-bottom: 15px;
}

.anotherLanguage {
  composes: formMargins;
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
