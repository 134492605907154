@import '../../styles/customMediaQueries.css';

/* 404 page */

.root {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 950px;
  width: 80%;
  margin-top: 60px;
  margin-bottom: 60px;

  @media (--viewportMedium) {
    margin-top: 10vh;
    flex-direction: row;
  }
}

.textContent {
  width: 100%;

  @media (--viewportMedium) {
    width: 70%;
    max-width: 630px;
    margin-top: 60px;
    margin-bottom: 60px;
    padding-right: 50px;
  }
}

.imageContent {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;

  @media (--viewportMedium) {
    max-width: 320px;
    width: 30%;
    margin-top: 0;
    padding-left: 50px;
  }
}

.notFoundImage {
  width: 110px;
  height: auto;
  margin-left: auto;
  margin-right: auto;

  @media (--viewportMedium) {
    width: 270px;
    height: auto;
  }
}

.number {
  composes: marketplaceHeroTitleFontStyles from global;
  text-align: left;
  color: #061F29;
  margin: 1px 0 0 0;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0.2px;

  @media (--viewportMedium) {
    margin: 0;
    font-size: 56px;
    font-weight: 700;
    line-height: 59px;
    letter-spacing: 0em;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
  text-align: center;
  margin-top: 16px;

  @media (--viewportMedium) {
    margin-top: 22px;
  }
}

.description {
  text-align: left;
  margin-bottom: 0;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;

  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2px;
  }
}

.searchForm {
  max-width: 408px;
  margin: 62px auto 0 auto;

  background-color: var(--matterColorLight);
  border-bottom: 4px solid var(--marketplaceColor);
  box-shadow: var(--boxShadowNotFoundPageSearch);

  @media (--viewportMedium) {
    margin-top: 64px;
  }
}
