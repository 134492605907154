.root {
  fill: var(--matterColorNegative);
}

.filled {
  fill: var(--marketplaceColor);
}

.customIcon {
  &.filled {
    fill: #FFC800;
  }
}
