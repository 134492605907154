@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--matterColorLight);

  /* Text positioning is done with paddings */
  
}

.heroContent {
  margin: 0 24px;

  @media (--viewportLarge) {
    margin: 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    /* max-width: 1200px; */
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroContentMentee {
  display: flex;
  flex-direction: column;
}

.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroMainTitle {
  /* composes: marketplaceHeroTitleFontStyles from global; */
  color: var(--darkBlue);
  composes: animation;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;

  
  width: 100%;  
  max-width: 1048px;
  margin: 0 auto 32px;

  text-align: center;
  
  @media (--viewportMedium) {
    text-align: left;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    max-width: 265px;
    height: 1px;
    background-color: var(--darkBlue);
    margin: 32px auto 0;
  }

  & br {
    display: none;
    @media (--viewportLarge) {
      display: block;
    }
  }

  & :global(.slot-counter-separator) {
    width: 5px;
    @media (--viewportMedium) {
      width: 20px;
    }
  }

  & :global(.slot-counter-char) {
    font-weight: 500;
    font-size: 24px;
    /* line-height: 1.2; */
    @media (--viewportMedium) {
      font-size: 48px;
    }
  }

  & :global(.slot-container) {
    transform: translateY(-4px);
  }
  
  & :global(.slot-container-hidden) {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4px);
  }
}

.staticText {
  font-weight: 500;
  font-size: 24px;
  line-height: 46px;
  margin-right: 14px;
  
  @media (--viewportMedium) {
    font-size: 48px;
    line-height: 60px;
  }
}

.blueText,
.mintText,
.redText {
  border-radius: 10px;
  padding: 0 4px;
  transform: translateY(-4px);
  display: block;
  text-align: center;
  
  @media (--viewportMedium) {
    display: inline-block;
  }
}

.blueText {
  background-color: var(--lightBlue);
}

.blueTextMain {
  position: relative;

  & > span {
    width: 100%;
  }

  &:after {
    content: ",";
    display: inline-block;
    position: absolute;
    right: -4px;
    top: 8px;
    
    @media (--viewportMedium) {
      right: -10px;
    }
  }
}

.mintText {
  background-color: var(--mint);
}

.redText {
  background-color: #f2aeae;
}

.heroSubTitle {
  composes: h4 from global;
  max-width: 728px;

  color: #9F9F9F;
  margin: 0 auto 32px;
  composes: animation;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
}

.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroSubTitleMentee {
  max-width: 466px;
}

.heroImg {
  display: block;
  composes: animation;
  text-align: center;
  order: 2;
  margin: 0 0 32px;
  
  @media (--viewportMedium) {
    order: unset;
  }

  & img {
    max-width: 100%;
  }
}

.heroImgFEDelay {
  animation-delay: 0.15s;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.heroButton1,
.heroButton2 {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  composes: animation;
  margin: 0 auto 20px;
  width: 100%;
  min-height: 44px;
  height: 44px;
  border-radius: 16px;
  padding: 0;
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 44px;

  @media (--viewportMedium) {
    display: block;
    width: 215px;
    min-height: 51px;
    height: 51px;
    line-height: 51px;
    margin: 0 20px 0;
  }
}

.heroButton1 {
  background-color: transparent;
  border: 1px solid var(--marketplaceColor);
  color: var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
    color: var(--matterColorLight);
  }
}

.heroButtonFEDelay {
  animation-delay: 0.45s;
}
