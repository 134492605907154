@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.descriptionWrapper {
  position: relative;
  left: 0;
  top: 0;
  width: calc( 100% - 48px );
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.descriptionRoot {
  width: 100%;
}

.descriptionInput {
  height: 208px;
}

.infoIcon {
  position: absolute;
  top: 8px;
  right: -42px;
}

.tooltipText {
  border-radius: 16px;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #FFFFFF;
  background-color: #061F29;
  padding: 20px;
}

.descriptionTooltip {
  text-align: center;
}

.certificate {
  composes: formMargins;
}

.skills {
  composes: formMargins;
}

.jobRoleName {
  composes: formMargins;
}

.projectsTitle {
  margin-top: 0;
  margin-bottom: 12px;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 135%;
  letter-spacing: 0.2px;
  color: #061F29;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 16px;
    padding: 1px 0 7px 0;
    line-height: 130%;
  }
}

.name {
  composes: formMargins;
}

.details {
  composes: formMargins;
}

.detailsRoot {
  width: calc( 100% - 48px );
}

.addProjectButton {
  flex-shrink: 0;
  margin-bottom: 12px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 168px;
    margin-bottom: 0;
  }
}

.removeProjectButton {
  flex-shrink: 0;
  margin-bottom: 24px;
  border: 2px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
  background-color: white;

  &:hover, &:focus {
    cursor: pointer;
    background-color: white;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: 168px;
    margin-bottom: 32px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 76px;
  }
}
