@import '../../styles/customMediaQueries.css';


.root {
  padding: 40px 0;
  background-color: var(--lightGray);
  
  @media (--viewportMedium) {
    padding: 80px 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 1352px;
  padding: 0 24px;
  margin: 0 auto;

  @media (--viewportMedium) {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
  }
  
  @media (--viewport1440) {
    padding: 0 36px;
  }
}

.contentItem {
  
  @media (--viewportMedium) {
    width: calc(50% - 20px);
  }
}

.title {
  margin: 0 0 32px;
  font-size: 24px;
  line-height: 36px;
  font-weight: var(--fontWeightMedium);
  color: var(--customBlack);
  text-align: center;
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  letter-spacing: -1.1px;
  
  @media (--viewportMedium) {
    text-align: left;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0;
  }
}

.subtitle {
  max-width: 500px;
  margin: 0 0 32px;
  color: var(--gray);

  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 1.5;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.list {
  margin-bottom: 40px;
  
  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.listItem {
  position: relative;
  margin-bottom: 24px;
  padding-left: 40px;
  line-height: 1.5;
  color: var(--gray);

  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 1.5;

  &::before {
    content: "";
    display: block;
    width: 20px;
    height: 26px;
    background-image: url('./images//list-icon.svg');
    position: absolute;
    left: 0;
    top: -2px;
  }
}

.listMentee {

  & .listItem {
    padding-left: 0;

    &::before {
      display: none;
    }
  }
}

.orangeText {
  color: var(--marketplaceColor);
  
  @media (--viewportMedium) {
    text-decoration: underline;
  }
}

.button {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  max-width: 200px;
  min-height: 44px;
  line-height: 44px;
  padding: 0;
  border-radius: 10px;
  background-color: var(--matterColorLight);
  border: 1px solid var(--marketplaceColor);
  color: var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    min-height: 50px;
    line-height: 50px;
  }
}

.buttonDesktop {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.buttonMobile {
  display: block;
  max-width: 100%;
  margin-top: 40px;

  @media (--viewportMedium) {
    display: none;
  }
}

.videoWrapper {
  display: flex;
  justify-content: flex-end;

  & iframe {
    height: auto;
    min-height: 182px;
    border-radius: 10px;
    
    @media (--viewportLarge) {
      min-height: 296px;
    }
  }
}
