@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  background-color: rgba( 255, 255, 255, 0 );
  margin: 0 24px 0 24px;
}

.titleArea {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.title {
  @apply --marketplaceH1FontStyles;
  background: linear-gradient(to right, #FA5853, #F4669B, #FFC444);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-align: center;
  font-weight: bold;

  margin: 0 auto;

  @media (--viewportMedium) {
    font-size: 45px;
  }
}

.subtitle {
  color: #303030;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  width: 100%;
  max-width: 918px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.steps {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  width: 100%;

  @media (--viewportMedium) {
    width: 50%;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepPictureWrapper {
  composes: step;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.stepPictureRightWrapper {
  composes: step;

  @media (--viewportMedium) {
    width: 50%;
    text-align: right;
  }
}

.stepTopLeftPictureWrapper {
  display: none;

  @media (--viewportMedium) {
    display: block;
    composes: step;
    width: 101px;
    height: auto;
  }
}

.rootTopSection {
  width: 100%;
  background: linear-gradient(112.2deg, #D3EAE6 1.04%, #FDFDF9 41.87%, #FBFAE3 80.25%);

  @media (--viewportMedium) {
    backdrop-filter: blur(4px);
    border-radius: 42px;
  }
}

.rootWrapper {
  width: 100%;
  height: 222px;
  background-image: url(./images/how-to-leave-feedback-for-your-mentor.png);
  background-position: center center;
  background-size: cover;

  @media (--viewportMedium) {
    height: 376px;
  }
}

.stepExplanationWrapper {
  composes: step;

  @media (--viewportMedium) {
    width: 100%;
    margin-top: 0;
  }
}

.stepActiveExplanationWrapper {
  composes: stepExplanationWrapper;
  box-shadow: 0 0 66px 0 rgba(229, 238, 253, 0.7);
}

.stepQuestionsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;

  @media (--viewportMedium) {
    margin-top: 40px;
    flex-direction: row;
  }
}

.stepQuestionsColumnLeft {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.stepQuestionsColumnRight {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    width: 50%;
    margin-left: 20px;
  }
}

.glowWrapper {
  padding: 10px;
  margin-bottom: 20px;
}

.activeGlowWrapper {
  composes: glowWrapper;
  box-shadow: 0 0 66px 0 rgba(229, 238, 253, 0.7);
}

.stepPicture {
  margin-top: 18px;
  margin-bottom: 18px;
  width: 100%;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 18px;
  }
}

.explanationPicture {
  width: 100%;
  max-width: 636px;
  height: auto;
}

.stepImage {
  width: 80px;
  height: 80px;
  text-align: center;
  padding-top: 20px;
  background-color: #FFE6D0;
  border-radius: 40px;
  box-shadow: 0px 4px 66px rgba(229, 238, 253, 0.7);

  @media (--viewportMedium) {
    margin-top: 80px;
  }
}

.titleSection {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.stepTitle {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 40px;
  line-height: 105%;
  text-align: center;
  color: #061F29;

  @media (--viewportMedium) {
    width: 100%;
    margin-top: 150px;
    padding-right: 140px!important;
    font-weight: 700;
    font-size: 56px;
    line-height: 105%;
    color: #061F29;
  }
}

.stepTitle2 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 18px;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 105%;
  text-align: center;
  color: #061F29;

  @media (--viewportMedium) {
    width: 100%;
    padding-right: 140px!important;
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 36px;
    line-height: 105%;
    color: #061F29;
  }
}

.activeStepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 21px;
  font-weight: bold;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.stepSubTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  font-weight: 500;
  font-size: 32px;
  line-height: 105%;
  color: #061F29;
  padding-bottom: 10px;

  @media (--viewportMedium) {
    margin-top: 120px;
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 36px;
    line-height: 114%;
  }
}

.stepSubSubTitle {
  margin-top: 20px;
  margin-bottom: 18px;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: 0.2px;
  line-height: 105%;
  color: #061F29;
  padding-bottom: 10px;

  @media (--viewportMedium) {
    padding-top: 25px;
    padding-bottom: 28px;
    font-size: 36px;
    line-height: 114%;
  }
}

.stepText {
  margin-top: 18px;
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 500;
  color: #9F9F9F;
  letter-spacing: 0.2px;

  @media (--viewportMedium) {
    margin-top: 21px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
  }
}

.stepFirstText {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 300;
  color: #9F9F9F;
  letter-spacing: 0.2px;

  @media (--viewportMedium) {
    margin-top: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
  }
}

.stepColumnText {
  composes: stepText;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.stepColumnTextBlock1 {
  width: 100%;

  @media (--viewportMedium) {
    padding-top: 0;
    width: 60%;
  }

  @media (--viewportLarge) {
    padding-top: 30px;
  }

  @media only screen and (min-width: 1200px) {
    padding-top: 60px;
  }

  @media only screen and (min-width: 1400px) {
    padding-top: 90px;
  }
}

.stepColumnTextBlock2 {
  width: 100%;

  @media (--viewportMedium) {
    width: 40%;
  }
}

.inlineImage {
  width: 100%;
  height: auto;
}

.buttonLine {
  width: 100%;
  margin-top: 20px;
  text-align: center;

  @media (--viewportMedium) {
    margin-top: 30px;
  }
}

.heroButton1,
.heroButton2 {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  composes: animation;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 51px;
  height: 51px;
  border-radius: 16px;
  padding-top: 17px;
  padding-bottom: 13px;
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;

  @media (--viewportMedium) {
    display: block;
    width: 215px;
    height: 51px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 28px;
    padding-bottom: 23px;
    font-size: 18px;
    line-height: 94%;
    border-radius: 24px;
    width: 325px;
    height: 74px;
  }
}

.heroButton1 {
  background-color: #D3EAE6;

  /* identical to box height, or 15px */

  text-align: center;

  color: #1D1D1D;

  &:hover {
    background-color: #B3CAC6;
  }

  @media (--viewportMedium) {
  }
}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.signupButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  margin-bottom: 20px;
  width: 100%;
  min-height: 51px;
  height: 51px;
  border-radius: 16px;
  border: 2px solid var(--marketplaceColor);
  padding-top: 13px;
  padding-bottom: 13px;
  color: var(--marketplaceColor);
  background-color: white;

  &:hover {
    color: var(--marketplaceColorDark);
    background-color: white;
  }

  @media (--viewportMedium) {
    display: block;
    width: 200px;
    height: 51px;
  }
}

.arrowImageWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.arrowImage {
  position: relative;
  right: 0;
  bottom: -30px;
  margin-top: 15px;
  width: 64px;
  height: 64px;
  margin-left: auto;

  &:hover {
    cursor: pointer;
  }
}

.diskList {
  list-style-type: disc;
  padding-left: 25px;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #9C9C9C;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 30px;
  }
}

.circleList {
  list-style-type: circle;
  padding-left: 30px;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #9C9C9C;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 30px;
  }
}

.squareList {
  list-style-type: square;
  padding-left: 60px;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #9C9C9C;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 30px;
  }
}
