@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
  text-align: left;
  margin: 0 0 100px;
}

.accordionItem {

  border: 1.5px solid #EBEBEB;
  border-radius: 24px;
  padding: 0;
  margin: 10px 0;
  
  /* &::after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background: linear-gradient(115.32deg, #F39663 -0.64%, #F0C5AB 48.7%, #E9E9EB 95.28%);
    border-radius: 47px;
  } */
}

.accordionItemActive {

  & .accordionDescription {
    display: block;
  }
}

.title {
  margin-top: 8px;
  margin-bottom: 40px;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: 0.2px;
  line-height: 105%;
  color: #061F29;
  padding-bottom: 10px;
  text-align: center;

  @media (--viewportMedium) {
    padding-top: 25px;
    padding-bottom: 28px;
    font-size: 36px;
    line-height: 114%;
    margin-right: auto;
    background-image: url("./images/right-image.png");
    background-position: center center;
  }

  & svg {
    width: 20px;
    height: 20px;
    stroke: #061F29;
    fill: #061F29;
  }
}

.accordionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0;
  padding: 40px 30px;

  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  text-transform: capitalize;

  color: #061F29;

  @media (--viewportMedium) {
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0.2px;
  }

  cursor: pointer;

  & svg {
    width: 20px;
    height: 20px;
    stroke: #061F29;
    fill: #061F29;
  }
}

.accordionDescription {
  display: none;
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 0 30px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #9F9F9F;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #9F9F9F;
  }

  &:last-of-type {
    padding-bottom: 20px;
  }
}
