@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.textareaRoot {
  width: 100%;
}

.textarea {
  composes: h4 from global;
  border: 0 none white;
  border-radius: 0;
  flex-grow: 1;

  /* Avoid text going behind the submit button */
  padding: 0;

  margin: 0;

  /* We need to remove horizontal paddings,
   since textarea uses content-box (autosize library fix). */
  width: calc(100% - 52px);

  @media (--viewportMedium) {
    margin: 0;
  }
  @media (--viewportLarge) {
    padding: 0 0 5px 0;
    margin: 0;
    width: 100%;
  }
}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}

.messageLine {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.attachImage {
  padding: 5px 12px 0 0;

  &:hover {
    cursor: pointer;
  }
}

.submitContainer {
  display: flex;
  flex-direction: row;
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
  padding: 26px 24px 0 0;
}

.error {
  composes: h5 from global;

  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.submitButton {
  composes: button buttonText buttonBordersSecondary buttonColorsSecondary from global;
  flex-grow: 0;

  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  font-weight: var(--fontWeightMedium);

  float: right;
  padding: 0;
  margin: 0;
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;

  display: inline-block;
  margin: 0;
  border: none;

  &:hover {
    transition: none;
    cursor: pointer;
  }
}

.sendIcon {
  margin: -3px 5px 0 0;
}
