@import '../../styles/customMediaQueries.css';


.root {
  padding: 0 0 80px 0;
  
  @media (--viewportMedium) {
    padding: 0 0 140px 0;
    margin-top: -40px;
  }
}

.content {
  padding: 0 24px;
  margin: 0 auto;
  
  text-align: center;
  
  @media (--viewport1440) {
    max-width: 1246px;
    padding: 0 36px;
  }
}

.title {
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: var(--fontWeightMedium);
  font-size: 24px;
  line-height: 32px;
  
  text-align: center;
  color: var(--darkBlue);

  @media (--viewportMedium) {
    font-size: 36px;
    line-height: 46px;
    margin: 32px 0;
  }
}

.subTitleMobile {
  text-align: center;
  margin: 0 0 32px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: var(--fontWeightRegular);
  color: var(--gray);

  @media (--viewportMedium) {
    display: none;
  }
}

.cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 32px;
}

.cardsItem {
  width: 100%;
  background-color: var(--lightOrangeAlt);
  padding: 20px;
  border-radius: 20px;
  margin: 0 0 10px;
  
  text-align: left;
  
  @media (--viewportMedium) {
    width: calc(50% - 10px);
    margin: 0 0 20px;
  }


  @media (--viewport1280) {
    width: calc(25% - 10px);
    margin: 0;
  }

  & svg {
    fill: none;
  }
}

.cardsItemImg {
  margin: 0;
}

.cardsItemTitle {
  margin: 10px 0;
  color: var(--customBlack);

  font-size: 18px;
  line-height: 26px;
  font-weight: var(--fontWeightMedium);
  
  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.cardsItemText {
  margin: 10px 0 0;
  color: var(--darkGray);
  font-weight: var(--fontWeightRegular);
  line-height: 1.5;
  font-size: 16px;
}

.button {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  min-height: 44px;
  line-height: 44px;
  padding: 0;
  margin: 0 auto;
  border-radius: 10px;

  @media (--viewportMedium) {
    max-width: 240px;
    min-height: 50px;
    line-height: 50px;
  }
}
