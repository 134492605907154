@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
  height: auto;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  color: #7A7A7A;
  width: 100%;
  text-align: left;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
  width: 100%;
  text-align: left;
}

.signupBlock {
  margin-top: 15px;
  width: 100%;
  text-align: left;
}

.signupLink {
  composes: marketplaceModalHelperLink from global;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #F39663;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
  }
}
