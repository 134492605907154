@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: #061F29;
  composes: animation;
  min-height: 156px;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 105%;

  @media (--viewportMedium) {
    font-weight: 500;
    font-size: 56px;
    line-height: 105%;
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    min-height: 256px;
    background-image: url(./images/title-background.png);
  }
}
.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitle {
  composes: h4 from global;

  color: #9F9F9F;
  margin: 0 0 32px 0;
  composes: animation;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;

  @media (--viewportMedium) {
    max-width: 465px;
    margin: 0 0 47px 0;
  }
}
.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.heroButton1,
.heroButton2 {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  composes: animation;
  margin-bottom: 20px;
  width: 100%;
  min-height: 51px;
  height: 51px;
  border-radius: 16px;
  padding-top: 17px;
  padding-bottom: 13px;
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;

  @media (--viewportMedium) {
    display: block;
    width: 215px;
    height: 51px;
    font-size: 16px;
    line-height: 94%;
  }
}

.heroButton1 {
  background-color: #D3EAE6;
  margin-right: 20px;

  /* identical to box height, or 15px */

  text-align: center;

  color: #1D1D1D;

  &:hover {
    background-color: #B3CAC6;
  }

  @media (--viewportMedium) {
  }
}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.arrowImageLink {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.arrowImage {
  margin-top: 80px;
  margin-bottom: 25px;

  &:hover {
    cursor: pointer;
  }
}
