@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
  background-color: rgba( 255, 255, 255, 0 );
  margin: 0 24px 0 24px;
}

.titleArea {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.title {
  @apply --marketplaceH1FontStyles;
  background: linear-gradient(to right, #FA5853, #F4669B, #FFC444);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-align: center;
  font-weight: bold;

  margin: 0 auto;

  @media (--viewportMedium) {
    font-size: 45px;
  }
}

.subtitle {
  color: #303030;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  width: 100%;
  max-width: 918px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.steps {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 140px;
  }
}

.step {
  width: 100%;

  @media (--viewportMedium) {
    width: 50%;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepPictureWrapper {
  composes: step;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.stepPictureRightWrapper {
  composes: step;

  @media (--viewportMedium) {
    width: 50%;
    text-align: right;
  }
}

.stepExplanationWrapper {
  composes: step;
  width: 100%;

  @media (--viewportMedium) {
    margin-top: 50px;
  }
}

.stepActiveExplanationWrapper {
  composes: stepExplanationWrapper;
  box-shadow: 0 0 66px 0 rgba(229, 238, 253, 0.7);
}

.glowWrapper {
  padding: 10px;
  margin-bottom: 20px;
}

.activeGlowWrapper {
  composes: glowWrapper;
  box-shadow: 0 0 66px 0 rgba(229, 238, 253, 0.7);
}

.stepPicture {
  margin-top: 18px;
  margin-bottom: 18px;
  width: 100%;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.explanationPicture {
  width: 100%;
  max-width: 638px;
  height: auto;
}

.stepImage {
  width: 80px;
  height: 80px;
  text-align: center;
  padding-top: 20px;
  background-color: #FFE6D0;
  border-radius: 40px;
  box-shadow: 0px 4px 66px rgba(229, 238, 253, 0.7);

  @media (--viewportMedium) {
    margin-top: 80px;
  }
}

.stepTitle {
  margin-top: 0;
  margin-bottom: 18px;
  font-weight: 500;
  font-size: 32px;
  line-height: 105%;
  color: #061F29;
  background-image: url(./images/text-background.png);
  background-position: top right;
  padding-bottom: 10px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 36px;
    line-height: 114%;
  }
}

.activeStepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 21px;
  font-weight: bold;
  color: #061F29;
  border-bottom: 6px solid #061F29;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.stepSubTitle {
  margin-top: 20px;
  margin-bottom: 18px;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: 0.2px;
  line-height: 105%;
  color: #061F29;
  padding-bottom: 10px;

  @media (--viewportMedium) {
    padding-top: 25px;
    padding-bottom: 28px;
    font-size: 36px;
    line-height: 114%;
    background-image: url(./images/right-image.png);
    background-position: center center;
  }
}

.explanationBlocks {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    flex-direction: row;
    max-width: 460px;
    margin-bottom: 40px;
  }
}

.explanationBlock {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.explanationItemBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.explanationStepBlocks {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    flex-direction: row;
    max-width: 460px;
    margin-bottom: 40px;
  }
}

.stepSubtitle {
  margin-top: 8px;
  margin-bottom: 0;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: 0.2px;
  line-height: 105%;
  color: #061F29;
  padding-bottom: 10px;

  @media (--viewportMedium) {
    padding-top: 25px;
    padding-bottom: 28px;
    font-size: 36px;
    line-height: 114%;
    max-width: 600px;
    margin-right: auto;
    background-image: url(./images/right-image.png);
    background-position: center center;
  }
}

.stepSubtitle2 {
  composes: stepSubtitle;

  @media (--viewportMedium) {
    max-width: 750px;
    background-position: left 30px center;
  }
}

.rightStepSubtitle {
  composes: stepSubtitle;
  margin-left: 30px;

  @media (--viewportMedium) {
    margin-left: 40px;
  }
}

.activeStepSubtitle {
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  color: #061F29;
  border-bottom: 6px solid #061F29;
  width: 50%;
  text-align: center;

  @media (--viewportMedium) {
    margin-top: 11px;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 700;
  }
}

.rightActiveStepSubtitle {
  composes: activeStepSubtitle;
  margin-left: 30px;

  @media (--viewportMedium) {
    margin-left: 40px;
  }
}

.stepItemRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.stepItemFigure {
  background-color: #EFEFEF;
  border-radius: 24px 24px 0 24px;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #BABABA;
  width: 48px;
  height: 48px;
  text-align: center;
  padding-top: 8px;
  margin-right: 25px;

  @media (--viewportMedium) {
    margin-right: 35px;
  }
}

.stepItemActiveFigure {
  background-color: var(--marketplaceColor);
  border-radius: 24px 24px 0 24px;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: white;
  width: 48px;
  height: 48px;
  text-align: center;
  padding-top: 8px;
  margin-right: 25px;

  @media (--viewportMedium) {
    margin-right: 35px;
  }
}

.stepItemText {
  font-weight: 500;
  font-size: 18px;
  line-height: 102%;
  letter-spacing: 0.2px;
  padding-top: 8px;
  color: #EFEFEF;

  @media (--viewportMedium) {
    line-height: 22px;
    font-size: 20px;
    font-weight: 700;
  }
}

.stepItemActiveText {
  font-weight: 500;
  font-size: 18px;
  line-height: 102%;
  letter-spacing: 0.2px;
  padding-top: 8px;
  color: #061F29;

  @media (--viewportMedium) {
    line-height: 22px;
    font-size: 24px;
    font-weight: 700;
  }
}

.stepItemPipe {
  border-left: 1px solid #EFEFEF;
  margin-left: 23px;
  width: 100px;
  height: 32px;
}

.middleTextBlock {
  @media (--viewportLarge) {
    background-image: url(./images/middle-background.png);
    background-position: right center;
  }
}

.stepText {
  margin-top: 18px;
  margin-bottom: 18px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #9F9F9F;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #9F9F9F;
  }
}

.stepTldrSubtitle {
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 114%;
  letter-spacing: 0.2px;
  font-size: 24px;
  color: #061F29;

  @media (--viewportMedium) {
    font-size: 36px;
    margin-bottom: 20px;
  }
}

.stepIllustration {
  width: 100%
}

.illustration {
  width: 100%;
  height: auto;
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.signupButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  margin-bottom: 20px;
  width: 100%;
  min-height: 51px;
  height: 51px;
  border-radius: 16px;
  border: 2px solid var(--marketplaceColor);
  padding-top: 13px;
  padding-bottom: 13px;
  color: var(--marketplaceColor);
  background-color: white;

  &:hover {
    color: var(--marketplaceColorDark);
    background-color: white;
  }

  @media (--viewportMedium) {
    display: block;
    width: 200px;
    height: 51px;
  }
}
