@import '../../styles/customMediaQueries.css';

.root {
  padding: 0 0 80px;
  
  @media (--viewportMedium) {
    padding: 0 0 140px 0;
  }
}

.content {
  max-width: 1352px;
  margin: 0 auto;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.contentItem {

  &:first-child {
    flex: 0 1 686px;
  }

  &:last-child {
    flex: 0 0 332px;
  }
}

.title {
  margin: 0 0 32px;
  font-size: 36px;
  line-height: 46px;
  font-weight: var(--fontWeightMedium);
  color: var(--darkBlue);
  text-align: center;

  & br {

    @media (--viewportMedium) {
      display: none;
    }
  }
}

.reviewsWrapper {
  position: relative;
  width: 100%;
  
  @media (--viewportMedium) {
    padding: 0 70px;
  }

  & :global(.alice-carousel__stage-item) {
    padding: 5px;
    min-width: 220px;
    
    @media (--viewportMedium) {
      padding: 10px;
    }
  }

  & :global(.alice-carousel__prev-btn),
  & :global(.alice-carousel__next-btn) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    padding: 0;
  }

  & :global(.alice-carousel__prev-btn) {
    left: -34px;
  }

  & :global(.alice-carousel__next-btn) {
    right: -34px;
  }

  & :global(.alice-carousel__slide-info) {
    top: auto;
    bottom: -44px;
    right: 10px;
    padding: 0;
    color: var(--darkGray);
    background-color: transparent;
    user-select: none;
  }
}

.reviewsItem {
  border: 1px solid var(--lightOrange);
  border-radius: 10px;
  padding: 20px;
}

.reviewsImgHolder {
  position: relative;
  width: 117px;
  height: 117px;

  &::before {
    content: "";
    display: block;
    width: 117px;
    height: 117px;
    background-color: var(--mint);
    border-radius: 20px;

    position: absolute;
    top: -5px;
    left: -5px;
    z-index: -1;
  }
}

.reviewsImg {
  width: 117px;
  height: 117px;
  border-radius: 20px;
  object-fit: cover;
  object-position: top;
}

.reviewsTitleHolder {
  display: flex;
}

.reviewsTitle {
  margin-right: 10px;
  font-size: 20px;
  line-height: 28px;
  font-weight: var(--fontWeightMedium);
  color: var(--darkBlue);
}

.reviewsRating {
  display: flex;
  align-items: center;

  & span {
    margin-left: 10px;
    color: var(--darkBlue);
  }
}

.reviewsPosition {
  margin-top: 0;
  font-size: 14px;
  line-height: 1.5;
  color: var(--blue);
  font-weight: var(--fontWeightRegular);
}

.reviewsDescription {
  font-weight: var(--fontWeightRegular);
  color: var(--darkBlue);
  font-size: 16px;
  line-height: 1.5;
  min-height: 290px;
  
  @media (--viewportLarge) {
    min-height: 230px;
  }
}
