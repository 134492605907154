@import '../../styles/customMediaQueries.css';


.root {

}

.rootHomepage {
  padding: 40px 0 0;
  
  @media (--viewportMedium) {
    padding: 60px 0 0;
  }
}

.content {
  max-width: 1352px;
  padding: 0 24px;
  margin: 0 auto;
  
  @media (--viewport1440) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 36px;
  }
}

.title {
  margin: 0 0 32px;
  font-size: 36px;
  line-height: 46px;
  font-weight: var(--fontWeightMedium);
  color: var(--customBlack);
  text-align: center;
  
  @media (--viewportMedium) {
    color: var(--darkBlue);
    text-align: left;
  }
  
  @media (--viewport1440) {
    max-width: 314px;
  }
}
