@import '../../styles/customMediaQueries.css';

.unsupportedCountryError {
  color: var(--failColor);
}

.root {
  /* Parent component should not shrink this field */
  flex-shrink: 0;

  /* This component uses flexbox layout too */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.input {
  /* Parent component should not shrink this field */
  flex-shrink: 0;
  border-color: var(--attentionColor);
  margin-bottom: 24px;
  width: 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.longForm {
  width: 100%;
  margin-bottom: 24px;
}

@media (--viewportSmall) {
  .longForm {
    width: calc(50% - 9px);
  }
}

.inputSuccess {
  border-color: var(--successColor);
}

.inputError {
  border-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.infoIcon {
  position: absolute;
  top: 43px;
  right: 5px;
}

.tooltipText {
  border-radius: 16px;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #FFFFFF;
  background-color: #061F29;
  padding: 20px;
}

.descriptionWrapper {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  margin-bottom: 16px;
}

.descriptionTooltip {
  text-align: center;
}
