@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.formCoreContainer {
  padding: 16px;
  background-color: white;
  box-shadow: 8px -5px 41px rgba(0, 0, 0, 0.06);
  border-radius: 24px;

  @media (--viewportLarge) {
    padding: 32px;
  }
}

.formDiscoveryContainer {
  padding: 16px;
  background-color: white;
  box-shadow: 8px -5px 41px rgba(0, 0, 0, 0.06);
  border-radius: 24px;

  @media (--viewportLarge) {
    padding: 32px;
 }
}

.requestButtonsContainer {
  border-top: 1px solid #D8D8D8;
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.requestButtonsDividerLabel {
  width: 30px;
  position: relative;
  top: -13px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.2px;

  color: #9C9C9C;
  background-color: white;
}

.requestButtonsEnableNote {
  width: 100%;
  text-align: center;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.2px;

  color: blue;
  background-color: white;
}

.card {
  composes: marketplaceInputStyles from global;

  /* Layout */
  padding: 8px 0 6px 0;
  height: 36px;

  /* Border */
  border-color: var(--attentionColor);

  @media (--viewportMedium) {
    height: 38px;
  }
  @media (--viewportLarge) {
    height: 54px;
    padding: 14px;
  }
}

.cardSuccess {
  border-bottom-color: var(--successColor);
}

.cardError {
  border-bottom-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.paymentHeading {
  margin: 0 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 135%;

  color: #061F29;

  @media (--viewportMedium) {
    margin: 0 0 16px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentMethodSelector {
  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.saveForLaterUse {
  padding-top: 6px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 48px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  composes: h5 from global;

  color: var(--matterColor);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  composes: h5 from global;

  color: var(--matterColorAnti);
  display: inline-block;
  padding: 0px 0 0px 26px;
  margin-top: -2px;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentLabel {
  margin: 0;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 2px;
  }
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 36px 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: auto;

  @media (--viewportLarge) {
    margin-top: 24px;
  }
}

.paymentInfo {
  composes: h5 from global;

  color: var(--matterColorAnti);
  text-align: center;
  padding: 0 42px;
  margin: 28px 0 0 0;

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0;
  }
}

.buttonsClause {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.backButton {
  margin-top: 22px;
  padding-top: 23px;
  border-radius: 16px;
  color: var(--marketplaceColor);
  background-color: white;
  border: 1.5px solid #F39663;
  width: 100%;
  text-align: center;
  height: 65px;
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;

  @media (--viewportMedium) {
    margin-top: 26px;
    margin-right: 12px;
    width: 116px;
  }

  @media (--viewportLarge) {
    margin-top: 17px;
  }
}

.submitButton {
  margin-top: 22px;
  border-radius: 16px;
  width: 100%;
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;

  @media (--viewportMedium) {
    margin-top: 26px;
    width: 228px;
  }

  @media (--viewportLarge) {
    margin-top: 17px;
  }
}

.missingStripeKey {
  color: var(--failColor);
}
