@import '../../styles/customMediaQueries.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
}

.field {
  margin: 10px 0 0 0;
  color: white;

  @media (--viewportMedium) {
    margin: 10px 0 0 0;
  }
}

.fieldInput {
  &:focus::placeholder {
    color: transparent;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.submitButton {
}

.submitButtonSuccess {
  composes: submitButton;
  background-color: var(--successColor);
}
