@import '../../styles/customMediaQueries.css';
.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 0;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 135%;
  letter-spacing: 0.2px;
  color: #061F29;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 1px 0 7px 0;
    line-height: 130%;
    color: #212121;
  }
}

.subtitle {
  margin-bottom: 0;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  color: #212121;
}

.footnote {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #9E9E9E;
}

.priceCurrencyInvalid {
  color: var(--failColor);
}
