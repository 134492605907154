@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 0;
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    padding: 36px 36px 0 36px;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: white; /*var(--matterColorNegative);*/ /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h4 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  /* margin-left: 25px; */
  width: 100%;

  @media (--viewportMedium) {
    max-width: 1056px;
    padding-top: 75px;
    margin: 0 auto 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    margin: 0 auto 117px;
  }

  @media screen and (min-width: 1336px) {
    flex-basis: 1336px;
    max-width: 1336px;
    margin-left: auto;
    margin-right: auto;
  }
}

.backButtonLink {
  width: 100%;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
   letter-spacing: 0.2px;
  text-decoration-line: underline;
  color: #F39663;
  padding: 0 24px 10px 24px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium){
    font-size: 16px;
    line-height: 24px;
    padding: 0 24px 10px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px 10px 36px;
  }
}

.contentContainer {
  /* margin: 0 25px 25px 25px; */
  margin: 0 25px 25px 0;
  width: 100%;
  padding: 0 24px;

  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto 0 auto;
    /* padding: 0 24px; */
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media screen and (min-width: 1336px) {
    flex-basis: 1336px;
    max-width: 1336px;
    margin-left: auto;
    margin-right: auto;
  }
}

.mainContent {
  flex-basis: 100%;

  @media (--viewportLarge) {
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.listingCard {
  flex-basis: 100%;
  margin-bottom: 35px!important;

  @media (--viewportMedium) {
    flex-basis: 370px;
    margin-right: 30px;
    margin-bottom: auto!important;
  }

}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 20px;

    /* The calendar needs 312px */
    flex-basis: 412px;
    flex-shrink: 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 31px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.rollUnrollIcon {
  position: absolute;
  right: 21px;
  top: 21px;

  @media (--viewportMedium) {
    display: none;
  }
}

.sectionDescription {
  padding: 16px;
  margin-bottom: 35px;
  background: #FFFFFF;
  box-shadow: 8px -5px 41px rgba(0, 0, 0, 0.06);
  border-radius: 24px;
  position: relative;

  @media (--viewportMedium) {
    padding: 32px;
    margin-bottom: 36px;
  }
}

.descriptionTitle {
  /* Font */
  composes: h3 from global;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 135%;
  letter-spacing: 0.2px;
  color: #061F29;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 114%;
    margin: 0;
  }
}

.description {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-top: 12px;
  }
}

.unrolledDescription {
  display: block;
  margin-top: 12px;
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectors {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.unrolledSectors {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 0;
}

.publicSectors,
.privateSectors {
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sectorsLabel {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #061F29;
  margin-top: 15px;
  margin-right: 10px;
}

.sectorName {
  background: #F1F1F1;
  border-radius: 53px;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #061F29;
  padding: 5px 10px;
  margin-top: 10px;
  margin-right: 10px;
}

.experienceRecords {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.unrolledExperienceRecords {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 0;
}

.workRecord {
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.jobRoleLabel {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #061F29;
}

.organisationName {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #9E9E9E;
}

.organizationTimeline {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #9E9E9E;
}

.projectRecords {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.unrolledProjectRecords {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 0;
}

.projectRecord {
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.projectLabel {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #061F29;
}

.projectDescription {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #9E9E9E;
}

.languages {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.unrolledLanguages {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 0;
}

.languagesList {
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.languageName {
  background: #F1F1F1;
  border-radius: 53px;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #061F29;
  padding: 5px 10px;
  margin-top: 10px;
  margin-right: 10px;
}

.skills {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.unrolledSkills {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 0;
}

.skillsList {
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.skillName {
  background: #F1F1F1;
  border-radius: 53px;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #061F29;
  padding: 5px 10px;
  margin-top: 10px;
  margin-right: 10px;
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  padding: 16px;
  margin-bottom: 5px;
  background: #FFFFFF;
  box-shadow: 8px -5px 41px rgba(0, 0, 0, 0.06);
  border-radius: 24px;

  @media (--viewportMedium) {
    padding: 32px;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  composes: h3 from global;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 135%;
  letter-spacing: 0.2px;
  color: #061F29;
  margin: 0 0 12px 0;

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 114%;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.searchListingsPanel {
  flex-grow: 1;
}

.footerRootClassName {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 20px 24px 0 24px!important;
  background-color: #061F29;

  @media (--viewportMedium) {
    padding: 20px 24px 0 24px!important;
  }

  @media (--viewportLarge) {
    padding: 20px 0 0 0;
    border-top: none;
  }
}

.footer {
  padding-top: 20px!important;
}
