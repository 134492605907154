@import '../../styles/customMediaQueries.css';


.root {
  padding: 80px 0;
  
  @media (--viewportMedium) {
    padding: 140px 0 140px;
  }
}

.content {
  padding: 0 24px;
  margin: 0 auto;
  
  text-align: center;
  
  @media (--viewport1440) {
    max-width: 1246px;
    padding: 0 36px;
  }
}

.title {
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: var(--fontWeightMedium);
  font-size: 36px;
  line-height: 46px;
  
  text-align: center;
  color: var(--darkBlue);

  @media (--viewportMedium) {
    font-size: 48px;
    line-height: 62px;
    margin: 0 0 32px 0;
  }
}

.subTitle {
  margin: 0 0 32px;
  color: var(--gray);
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 1.5;
}

.subTitleForMentor {
  max-width: 664px;
  margin: 0 auto 32px;
}

.subTitleForMentee {
  max-width: 1032px;
  margin: 0 auto 32px;
}

.orangeText,
.orangeTextUnderline {
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);
}

.orangeTextUnderline {
  text-decoration: underline;
}

.cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 32px;
}

.cardsItem {
  width: calc(50% - 5px);
  background-color: var(--mint);
  padding: 20px;
  border-radius: 20px;
  margin: 0 0 10px;
  
  text-align: left;
  
  @media (--viewportMedium) {
    width: calc(50% - 10px);
    margin: 0 0 20px;
  }

  &:last-child {
    width: 100%;

    @media (--viewportMedium) {
      width: calc(50% - 10px);
    }

    @media (--viewport1280) {
      width: calc(20% - 10px);
      margin: 0;
    }
  }

  @media (--viewport1280) {
    width: calc(20% - 10px);
    margin: 0;
  }

  & svg {
    fill: none;
    min-height: 60px;
  }
}

.cardsItemImg {
  margin: 0;
}

.cardsItemTitle {
  margin: 10px 0;
  color: var(--customBlack);

  font-size: 18px;
  line-height: 26px;
  font-weight: var(--fontWeightMedium);
  
  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.cardsItemText {
  margin: 0;
  color: var(--darkGray);
  font-weight: var(--fontWeightRegular);
  line-height: 1.5;
  font-size: 16px;
}

.button {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  min-height: 44px;
  line-height: 44px;
  padding: 0;
  margin: 0 auto;
  border-radius: 10px;

  @media (--viewportMedium) {
    max-width: 240px;
    min-height: 50px;
    line-height: 50px;
  }
}

/* .buttonMint {
  background-color: var(--mint);
  color: var(--customBlack);
  
  &:hover {
    background-color: #B3CAC6;
    color: var(--matterColorLight);
  }
} */

.videoWrapper {
  display: flex;
  justify-content: flex-end;

  & iframe {
    height: auto;
    min-height: 182px;
    border-radius: 10px;

    @media (--viewportLarge) {
      min-height: 296px;
    }
  }
}
