@import '../../styles/customMediaQueries.css';

.root {
  & p {
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 17px;
      margin-bottom: 15px;
    }
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }
}

.lastUpdated {
  composes: marketplaceBodyFontStyles from global;
  margin-top: 0;
  margin-bottom: 55px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 54px;
  }
}

.diskList {
  list-style-type: disc;
}

.circleList {
  list-style-type: circle;
  padding-left: 30px;
}

.squareList {
  list-style-type: square;
  padding-left: 60px;
}

.contactingEntitiesTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #DDDDDD;
}

.contactingEntitiesRow {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #DDDDDD;
}

.contactingEntitiesLastRow {
  display: flex;
  flex-direction: row;
  border: none;
}

.contactingEntitiesCell {
  font-size: 10px;
  border-right: 1px solid #DDDDDD;
  width: 25%;
  padding: 3px;

  @media (--viewportMedium) {
    font-size: 16px;
    padding: 10px;
  }
}

.contactingEntitiesLastCell {
  font-size: 10px;
  border: none;
  width: 25%;
  padding: 3px;

  @media (--viewportMedium) {
    font-size: 16px;
    padding: 10px;
  }
}
