.root {
}

.select {
  color: var(--matterColorAnti);

  padding: 4px 4px 4px 4px;
  border-color: #E4E4E4;
  border-radius: 16px;
  /* Borders */
  border-width: 1px;
  border-style: solid;

  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }

  @media (--viewportMedium) {
    padding: 4px 4px 4px 4px;
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-color: var(--successColor);
}

.selectError {
  border-color: var(--failColor);
}
