@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
  background-color: #D3EAE6;
  margin: 0 8px 0 8px;
  border-radius: 16px;
  background-image: url(./images/top-image.png);
  background-position: top right;

  @media (--viewportMedium) {
    border-radius: 40px;
    background-image: url(./images/right-image.png);
    background-position: bottom right;
  }
}

.titleArea {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.title {
  @apply --marketplaceH1FontStyles;
  background: linear-gradient(to right, #FA5853, #F4669B, #FFC444);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-align: center;
  font-weight: bold;

  margin: 0 auto;

  @media (--viewportMedium) {
    font-size: 45px;
  }
}

.subtitle {
  color: #303030;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  width: 100%;
  max-width: 918px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.steps {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;

  @media (--viewportMedium) {
    flex-direction: row;
    padding-bottom: 70px;
  }
}

.step {
  width: 100%;

  @media (--viewportMedium) {
    width: 50%;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepPictureWrapper {
  composes: step;
  display: none;

  @media (--viewportMedium) {
    display: block;
    width: 101px;
    height: auto;
  }
}

.stepPictureRightWrapper {
  composes: step;

  @media (--viewportMedium) {
    width: 50%;
    text-align: right;
  }
}

.stepExplanationWrapper {
  composes: step;
  width: calc( 100% - 32px );
  margin: 0 16px 0 16px;

  @media (--viewportMedium) {
    width: 60%;
    margin-top: 50px;
  }
}

.stepActiveExplanationWrapper {
  composes: stepExplanationWrapper;
  box-shadow: 0 0 66px 0 rgba(229, 238, 253, 0.7);
}

.glowWrapper {
  padding: 10px;
  margin-bottom: 20px;
}

.activeGlowWrapper {
  composes: glowWrapper;
  box-shadow: 0 0 66px 0 rgba(229, 238, 253, 0.7);
}

.stepPicture {
  margin-top: 0;
  margin-bottom: 18px;
  width: 100%;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 18px;
  }
}

.explanationPicture {
  width: 100%;
  max-width: 621px;
  height: auto;
}

.stepImage {
  width: 80px;
  height: 80px;
  text-align: center;
  padding-top: 20px;
  background-color: #FFE6D0;
  border-radius: 40px;
  box-shadow: 0px 4px 66px rgba(229, 238, 253, 0.7);

  @media (--viewportMedium) {
    margin-top: 80px;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 105%;
  color: #061F29;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
    font-weight: 700;
    font-size: 50px;
    line-height: 128%;
  }
}

.activeStepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 21px;
  font-weight: bold;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.stepText {
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 17px;
  font-weight: 300;
  color: #9F9F9F;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.actionButtons {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.searchButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  margin-bottom: 20px;
  width: 100%;
  min-height: 51px;
  height: 51px;
  border-radius: 16px;
  padding-top: 13px;
  padding-bottom: 13px;
  color: #1D1D1D;
  background-color: white;

  &:hover {
    color: var(--marketplaceColorDark);
    background-color: white;
  }

  @media (--viewportMedium) {
    display: block;
    width: 200px;
    height: 51px;
    margin-right: 20px;
  }
}

.signupButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  margin-bottom: 20px;
  width: 100%;
  min-height: 51px;
  height: 51px;
  border-radius: 16px;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: var(--marketplaceColor);
  color: white;

  &:hover {
    background-color: var(--marketplaceColorDark);
    color: white;
  }

  @media (--viewportMedium) {
    display: block;
    width: 200px;
    height: 51px;
  }
}
