@import '../../styles/customMediaQueries.css';

.root {
  padding: 0 0 80px 0;

  @media (--viewportMedium) {
    padding: 0 0 140px 0;
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 1352px;
  padding: 0 24px;
  margin: 0 auto;
  
  text-align: center;
  
  @media (--viewport1440) {
    padding: 0 36px;
    flex-direction: row;
  }
}

.contentItem {
  width: 100%;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.title {
  margin: 0 0 24px;
  font-size: 24px;
  line-height: 32px;
  font-weight: var(--fontWeightMedium);
  color: var(--customBlack);
  font-family: "Rany", Helvetica, Arial, sans-serif;
  
  @media (--viewportMedium) {
    font-size: 36px;
    line-height: 46px;
    margin: 0 0 32px;
    color: var(--darkBlue);
  }

  & br {
    display: none;

    @media (--viewport1440) {
      display: block;
    }
  }
}

.subTitle {
  max-width: 586px;
  margin: 0 auto 32px;
  color: var(--gray);
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 1.5;
}


.button {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  margin: 0 auto;
  padding: 0;
  min-height: 44px;
  line-height: 44px;

  background-color: var(--mint);
  color: var(--customBlack);

  &:hover {
    background-color: #B3CAC6;
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    min-height: 50px;
    line-height: 50px;
    max-width: 200px;
  }
}
