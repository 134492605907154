@import '../../styles/customMediaQueries.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
  font-family: "Rany", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #333333;

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 114%;
  }
}

.field {
  margin: 25px 0 24px 0;

  @media (--viewportMedium) {
    margin: 22px 0 24px 0;
  }
}

.category {
  margin: 0 0 24px 0;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.submitButton {
  border-radius: 16px;
}

.cancelButton {
  border-radius: 16px;
  border: 2px solid var(--marketplaceColor);
  padding-top: 13px;
  padding-bottom: 13px;
  color: var(--marketplaceColor);
  background-color: white;
  margin-top: 15px;

  & :hover {
    color: var(--marketplaceColorDark);
    background-color: white;
  }
}
