@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
}

.longInfo {
  flex-wrap: wrap;
}

.filters {
  background-color: white;
  min-height: 265px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-top-right-radius: 21px;

  @media(--viewportMedium){
    min-height: 245px;
    border-top-right-radius: 50px;
  }

  & > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
  }
}

.filtersTop {
  width: 100%;
  height: 25px!important;
  margin: 0!important;
  background-image: url('./sloped-top.png');
  background-size: auto 25px;
  background-position: 21px 2px;
  display: flex;
  flex-direction: row;
  border-top-right-radius: 21px;

  @media(--viewportMedium){
    background-position: 50px 2px;
    border-top-right-radius: 50px;
  }
}

.filtersMain {
  background-color: #1C2431;
  width: 100%;
  height: 100%;
  border-top-left-radius: 21px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 21px;
  border-bottom-right-radius: 21px;
  background-image: url('./mobile-main-background.png');
  background-position: bottom left;

  @media(--viewportMedium){
    border-top-left-radius: 50px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    background-image: url('./desktop-main-background.png');
    background-position: bottom left;
  }
}

.filtersTitle {
  width: 100%;
}

.filtersTitleRight {
  width: 54px;
  height: 95px;
  background-image: url('./mobile-title-background.png');
  background-position: top 13px right;
  position: absolute;
  top: 5px;
  right: 0;

  @media(--viewportMedium){
    top: 13px;
    width: 91px;
    height: 121px;
    background-image: url('./desktop-title-background.png');
    background-position: top right;
  }


  @media screen and (min-width: 800px) {
    top: 11px;
  }

  @media screen and (min-width: 900px) {
    top: 9px;
  }

  @media screen and (min-width: 1000px) {
    top: 8px;
  }

  @media screen and (min-width: 1100px) {
    top: 6px;
  }

  @media screen and (min-width: 1200px) {
    top: 5px;
  }
}

.filtersHeader {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  text-align: left;

  @media(--viewportMedium){
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 80px;
  }
}

.headerTitle {
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 135%;
  letter-spacing: 0.2px;
  color: #FFFFFF;

  @media(--viewportMedium) {
    font-size: 36px;
    line-height: 135%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
  }
}

.headerSubtitle {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

.filtersWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 25px;

  @media(--viewportMedium) {
    flex-direction: row;
    padding: 0 80px;
  }
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.searchResultSummary {
  composes: h4 from global;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.noSearchResults {
  composes: h4 from global;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
  max-height: 40px;
}

.loadingResults {
  composes: h4 from global;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: white;

  @media (--viewportLarge) {
    padding: 9px 36px 0 36px;
  }
}

.resultsFound {
  display: none;
  white-space: nowrap;
  margin-left: 24px;

  @media (--viewportLarge) {
    margin-left: 0;
  }
}

.jobRoleTitle {
  white-space: nowrap;
  margin-left: 24px;
  font-size: 24px;

  @media (--viewportLarge) {
    font-size: 28px;
    margin-left: 0;
  }
}


.searchFiltersPanelClosed {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;

  display: inline-block;
  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);

  display: inline-block;
  padding: 9px 16px 9px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}
